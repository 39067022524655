<template>
  <div class="auth-wrapper auth-v1 px-2" style="background-image: url('bg.jpg');    background-position:center;  background-size: cover;">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1"> Welcome to SFC <span class="text-danger">Company</span> Tool 👋 </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <!-- username -->
              <b-form-group label-for="username" label="Username">
                <validation-provider #default="{ errors }" name="Username" rules="required">
                  <b-form-input id="username" v-model="userName" name="login-username" :state="errors.length > 0 ? false : null" placeholder="john" autofocus />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password" placeholder="Password" />

                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit button -->
              <b-button variant="primary" type="submit" @click="login" block>
                Sign in
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>
        <div class="text-center mt-2 cursor-pointer" @click="userScreen">Client Login Screen</div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { BCard, BSpinner, BOverlay, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import router from '@/router';
import useJwt from '@/auth/jwt/useJwt';
import axios from 'axios';

export default {
  components: {
    BCard,
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;

          /*
{
                params: {
                  userName: this.userName,
                  password: this.password,
                },
              } 
          */

          return new Promise((resolve, reject) => {
            axios
              .get('http://localhost/sfc-api/public/api/users', {
                params: {
                  userName: this.userName,
                  password: this.password,
                },
              })
              .then((response) => {
                resolve(response);
                //  console.log(response.data[0].accesslevel);
                //  const result = response.data.filter((element) => element.username == this.userName && element.password == this.password);

                if (response.data[0].accesslevel != 'Fail') {
                  var test = {
                    id: 1,
                    fullName: 'John Doe',
                    username: this.userName,
                    password: this.password,
                    email: 'admin@demo.com',
                    role: 'Admin',
                    ability: [
                      {
                        action: 'manage',
                        subject: 'all',
                      },
                    ],
                  };

                  useJwt.setToken(null);
                  useJwt.setRefreshToken(null);
                  localStorage.setItem('userData', JSON.stringify(test));

                  this.$ability.update(test.ability);

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${this.userName}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged `,
                    },
                  });
                  router.push({ path: '/' });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: `Error occurred while logging in`,
                    },
                  });
                  this.show = false;
                }
                /* response.data.forEach((element) => {



                  if (element.username == this.userName && element.password == this.password) {
                    console.log('ok');
                  } else {
                    console.log('hata');
                  }
                });*/
              })
              .catch((error) => reject(error));
          });

          /* useJwt
            .login({
              username: this.userName,
              password: this.password,
            })
            .then((response) => {


            /*  response.data.userData.ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ];*/
          //  console.log(response);
          /*  if (response.data.userData.id == 1) {
                  response.data.userData.ability = [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ];
                } else {
                  response.data.userData.ability = [
                    {
                      action: "read",
                      subject: "Auth",
                    },
                  ];
                }*/

          //  const { userData } = response.data;

          //  useJwt.setToken(response.data.accessToken);
          //  useJwt.setRefreshToken(response.data.refreshToken);
          //  localStorage.setItem('userData', JSON.stringify(userData));

          //  this.$ability.update(userData.ability);
          /*  this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.name || userData.surname}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              });


              this.show = false;
              router.push({ path: '/' });
            })
            .catch((error) => {
              console.log(error);

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Error occurred while logging in`,
                },
              });
              this.show = false;
              this.$refs.loginValidation.setErrors(error.response.data.error);

            });*/
        }
      });
    },

    userScreen() {
      router.push({ path: 'login' });
    },

    validationForm() {},
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
